export const actions = [
    'GetDashboard',

    'ListAliases',
    'GetAlias',
    'CreateAlias',
    'UpdateAlias',
    'DeleteAlias',

    'ListContacts',
    'GetContact',
    'CreateContact',
    'UpdateContact',
    'DeleteContact',

    'ListMessages',
    'GetMessage',
    'CreateMessage',
    'UpdateMessage',
    'DeleteMessage',

    'ListTags',
    'GetTag',
    'CreateTag',
    'UpdateTag',
    'DeleteTag',

    'ListServicePlans',
    'UpdateServicePlan',
    'DeleteServicePlan',

];

export const actionConstants = actions.reduce((acc, action) => {
    acc[action.replace(/[A-Z]/g, (match, offset) => (offset > 0 ? '_' : '') + match.toLowerCase())
              .toUpperCase()] = action;
    return acc;
}, {} as Record<string, string>);