export const listMessages = `
   query listMessages($filter: MessagesFilterInput, $limit: Int, $nextToken: String) {
       listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
           items {
              id
              messageId
              fromText
              fromHtml
              toText
              toHtml
              subject
              date
              htmlPath
              htmlObjectKey
              attachments {
                  filename
                  filePath
                  objectKey
              }
              tagIds
              tags {
                    items {
                      id
                      name
                  }
              }
              status
              created
              updated
           }
           nextToken
       }
   }
`;

export const getMessage = `
   query getMessage($id:ID!) {
       getMessage(id:$id) {
         id
         messageId
         fromText
         toText
         subject
         date,
         htmlPath
         textPath
         attachments {
          filename
          filePath
          objectKey
         }
         tagIds
         tags {
             items {
                  id
                  name
             }
         }
         status
         created
         updated
       } 
}`;
