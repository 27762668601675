import {
    ReactElement,
    useEffect,
} from 'react';

import {SimpleGrid,} from '@mantine/core';

import {SimpleTableBatchAction,} from '@flexinet/ui-components';
import {useAliasesSelection} from '../hooks/useAliasesSelection';
import {useAliases} from "../hooks/useAliases.ts";
import {useAliasesTableColumns} from "../hooks/useAliasesTableColumns.ts";
import {useAliasesSearch} from "../hooks/useAliasesSearch.ts";
import {useAliasesPaging} from "../hooks/useAliasesPaging.ts";
import {useAuth} from "../../../hooks/useAuth.ts";
import {
    DataTable,
    DataTableColumn
} from "mantine-datatable";
import {Message} from "../../messages/types.ts";
import classes from "../../messages/tables/RecordsTable.module.css";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";


export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {


    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    // const cognitoUsername = user.username;

    const {allowedActions} = useVerifiedPermissions()


    const {
        visibleItems,
        visibleCount,
        fetchRecords,
    } = useAliases();

    const {
        columns,
    } = useAliasesTableColumns();

    const {
        keyword,
        handleFilterByKeyword,
    } = useAliasesSearch();

    const {
        page,
        limit,
        handleItemsPerPageChange,
        handlePageChange,
    } = useAliasesPaging();


    const {
        selection,
        handleRowSelection,
        handleClearSelection,
    } = useAliasesSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols = [] as DataTableColumn[];

        // if (allowedActions.includes('DeleteAlias')) {
        //
        //     cols.push({
        //                   id: 'select',
        //                   title: '',
        //                   render: item => {
        //                       if (isAdmin || item.createdByUsername === cognitoUsername || item.managedByUsername === cognitoUsername) {
        //                           return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
        //                       } else {
        //                           return (<Center><Checkbox disabled={true}>n/a</Checkbox></Center>);
        //                       }
        //
        //                   },
        //
        //
        //               },
        //     );
        // }

        cols = cols.concat(columns);

        return cols;


    };


    let selectedRecords = undefined;
    if (allowedActions.includes('DeleteAlias')) {
        selectedRecords = Object.keys(selection)
                                .map(key => selection[key])
    }


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                keyword={keyword}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />

            <DataTable
                minHeight={200}
                noRecordsText="No records found"
                verticalSpacing={'sm'}
                withTableBorder
                borderRadius={'md'}
                shadow={'sm'}
                striped
                columns={getColumns()}
                records={visibleItems}
                totalRecords={parseInt(visibleCount)}
                recordsPerPage={parseInt(limit)}
                page={parseInt(page)}
                onPageChange={handlePageChange}
                paginationText={({
                                     from,
                                     to,
                                     totalRecords
                                 }) => `Records ${from} - ${to} of ${totalRecords}`}
                paginationSize={'md'}
                selectedRecords={selectedRecords}
                onSelectedRecordsChange={(items) => {
                    handleClearSelection();
                    items.forEach(item => {
                        handleRowSelection(item)
                    })
                }}
                rowClassName={(record: Message) => {
                    if (record.status === 'UNREAD') {
                        return classes.unread;
                    } else if (record.status === 'READ') {
                        return classes.read;
                    }
                }}
            />


        </SimpleGrid>
    );
};