import {
    ReactElement,
    useEffect,
} from 'react';

import {
    Box,
    Group,
    Paper,
    Stack,
    Text
} from '@mantine/core';

import {useMessages} from '../hooks/useMessages';

import {SimpleTableBatchAction,} from '@flexinet/ui-components';

import PropTypes from 'prop-types';
import {useMessagesSelection} from '../hooks/useMessagesSelection';
import {Message,} from '../types';
import {useMessagesSearch} from "../hooks/useMessagesSearch.ts";
import {useMessagesPaging} from "../hooks/useMessagesPaging.ts";
import {useMessagesTableColumns} from "../hooks/useMessagesTableColumns.ts";
import {AiOutlinePaperClip} from "react-icons/ai";
import {useAuth} from "../../../hooks/useAuth.ts";

import {DataTable} from 'mantine-datatable';
import classes from './RecordsTable.module.css';
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";
import {
    useNavigate,
    useParams
} from "react-router-dom";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";


export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {

    const {
        tag,
        status
    } = useParams()

    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    const {allowedActions} = useVerifiedPermissions()

    const {
        visibleItems,
        visibleCount,
        fetchRecords,
    } = useMessages();


    const {
        columns,
    } = useMessagesTableColumns();

    const {
        keyword,
        handleFilterByKeyword,
    } = useMessagesSearch();

    const {
        limit,
        page,
        handleItemsPerPageChange,
        handlePageChange,
    } = useMessagesPaging();

    const {
        selection,
        handleRowSelection,
        handleClearSelection,
    } = useMessagesSelection();


    // const {
    //     updateRecord,
    // } = useMessage(selectedRecord?.id);

    const navigate = useNavigate()

    useEffect(() => {
        fetchRecords();
    }, [tag, status]);


    const getColumns = () => {


        let cols = [];

        cols.push({
                      id: 'from',
                      accessor: 'fromText',
                      title: 'From',
                      ellipsis: true,
                      width: 500,
                      render: (item: Message) => {
                          return (
                              <Group>

                                  <Text fs={'sm'} fw={700} style={{cursor: 'pointer'}}>{item.fromText}</Text>
                                  <Box> {item.attachments && item.attachments.length > 0 ?
                                      <AiOutlinePaperClip/> : null}</Box>

                              </Group>
                          )

                      },

                  },
        );

        cols.push({
                      id: 'subject',
                      accessor: 'subject',
                      title: 'Subject',
                      ellipsis: true,
                      render: (item: Message) => {
                          return (
                              <Stack>
                                  {item.subject ? <Text fw={700} fs={'sm'}>{item.subject}</Text> :
                                      <Text fs={'sm'} fw={700}>n/a</Text>}
                              </Stack>
                          )

                      },

                  },
        );

        cols.push({
                      id: 'date',
                      accessor: 'date',
                      title: 'Received',
                      render: (item: Message) => formatTimestamp(item.date as string, 'MMM DD, YYYY'),
                  });


        cols = cols.concat(columns);

        return cols;

    };

    let selectedRecords = undefined;
    if (allowedActions.includes('DeleteMessage')) {
        selectedRecords = Object.keys(selection)
                                .map(key => selection[key])
    }


    return (
        <>
            <Paper p={'md'} shadow={'sm'}>
                <Stack>
                    <SimpleTableBatchAction
                        limit={limit}
                        handleItemsPerPageChange={handleItemsPerPageChange}
                        settings={settings}
                        keyword={keyword}
                        handleFilterByKeyword={handleFilterByKeyword}
                        extraTools={extraTools}
                    />


                    <DataTable
                        minHeight={200}
                        noRecordsText="No records found"
                        verticalSpacing={'sm'}
                        withTableBorder
                        borderRadius={'md'}
                        shadow={'sm'}
                        striped
                        columns={getColumns()}
                        records={visibleItems}
                        totalRecords={parseInt(visibleCount)}
                        recordsPerPage={parseInt(limit)}
                        page={parseInt(page)}
                        onPageChange={handlePageChange}
                        paginationText={({
                                             from,
                                             to,
                                             totalRecords
                                         }) => `Records ${from} - ${to} of ${totalRecords}`}
                        paginationSize={'md'}
                        selectedRecords={selectedRecords}
                        onSelectedRecordsChange={(items) => {
                            handleClearSelection();
                            items.forEach(item => {
                                handleRowSelection(item)
                            })
                        }}
                        onRowClick={({
                                         record,
                                     }) => {

                            navigate('/messages/' + record.id);

                            // if (record.status === 'UNREAD') {
                            //     updateRecord({...record, ...{status: 'READ'}});
                            // }

                        }}
                        rowClassName={(record: Message) => {
                            if (record.status === 'UNREAD') {
                                return classes.unread;
                            } else if (record.status === 'READ') {
                                return classes.read;
                            }
                        }}
                    />

                </Stack>

            </Paper>

        </>
    );
};

RecordsTable.propTypes = {
    extraTools: PropTypes.array,
    settings: PropTypes.object,
};