import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Alias} from '../types';
import {
    Badge,
    Group
} from "@mantine/core";
import {FlexiCopyButton} from "@flexinet/ui-components";

export const aliasesInitialColumnSet = [
    {
        id: 'name',
        accessor: 'name',
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: Alias) => <NavLink to={`/aliases/${item.id}`}>{item.name}</NavLink>,
    },
    {
        id: 'email',
        accessor: 'name',
        title: I18n.get('Email Address'),
        width: '1fr',
        render: (item: Alias) => <Group>{`${item.name}@flexi.email`}<FlexiCopyButton
            value={`${item.name}@flexi.email`}/></Group>,

    },
    {
        id: 'status',
        accessor: 'status',
        title: I18n.get('Status'),
        width: '1fr',
        render: (item: Alias) => item.status == 'Enabled' ? <Badge color={'green'}>Enabled</Badge> :
            <Badge color={'red'}>Disabled</Badge>,
    },
    {
        id: 'created',
        accessor: 'created',
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Alias) => formatTimestamp(item.created as string),
    },
];
