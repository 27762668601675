import {stage} from './stage';

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        enabled: boolean;
        policyStoreId: string;
        region: string;
        namespace: string
    };
};

const config: ConfigShape = {
    prod: {
        enabled: true,
        policyStoreId: '2axCYtEHf2fe3st83nbTUe',
        region: 'us-west-2',
        namespace: 'FlexiEmail'
    },
    test: {
        enabled: true,
        policyStoreId: 'G85kDjmrUTxe8Hktrnq57L',
        region: 'us-west-2',
        namespace: 'FlexiEmail',
    },
    dev: {
        enabled: true,
        policyStoreId: 'VxukYHbftMsjFABS9uvtXM',
        region: 'us-west-2',
        namespace: 'FlexiEmail',
    },
    ide: {
        enabled: true,
        policyStoreId: 'VxukYHbftMsjFABS9uvtXM',
        region: 'us-west-2',
        namespace: 'FlexiEmail',
    },
};

export default config[stage];
