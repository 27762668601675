import {stage} from '../stage';

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        S3: {
            bucket: string;
            region: string;
            isObjectLockEnabled: boolean;
        }
    };
};

const storage: ConfigShape = {
    prod: {
        S3: {
            bucket: 'prod-flexinet-ses-infra-p-parsedmessagesbucketf941-apbsp6nxotg3',
            region: 'us-west-2',
            isObjectLockEnabled: false //OPTIONAl - Object Lock parameter
        }
    },
    test: {
        S3: {
            bucket: 'test-flexinet-ses-infra-t-parsedmessagesbucketf941-f5m52eyxpqtc',
            region: 'us-west-2',
            isObjectLockEnabled: false //OPTIONAl - Object Lock parameter
        }
    },
    dev: {
        S3: {
            bucket: 'flexinet-ses-infra-dev-parsedmessagesbucketf941d74-8md3jpft2y38',
            region: 'us-west-2',
            isObjectLockEnabled: false //OPTIONAl - Object Lock parameter
        }
    },
    ide: {
        S3: {
            bucket: 'flexinet-ses-infra-dev-parsedmessagesbucketf941d74-8md3jpft2y38',
            region: 'us-west-2',
            isObjectLockEnabled: false //OPTIONAl - Object Lock parameter
        }
    },
};

export const config = storage[stage];
