import {stage} from './stage';

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        streamName: string;
        region: string;
    };
};

const kinesis: ConfigShape = {
    prod: {
        streamName: '',
        region: 'us-west-2',
    },
    test: {
        streamName: '',
        region: 'us-west-2',
    },
    dev: {
        streamName: '',
        region: 'us-west-2',
    },
    ide: {
        streamName: '',
        region: 'us-west-2',
    },
};

export default kinesis[stage];
