import {
    getServicePlan,
    listServicePlans
} from './queries';
import {
    createServicePlan,
    deleteServicePlan,
    updateServicePlan
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {ServicePlan} from "../types.ts";
import {
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";
import {generateClient} from "aws-amplify/api";


export type ListServicePlans = {
    listServicePlans: { items: ServicePlan[] }
}

export type GetServicePlan = {
    getServicePlan: ServicePlan
}

export type CreateServicePlan = {
    createServicePlan: ServicePlan
}

export type UpdateServicePlan = {
    updateServicePlan: ServicePlan
}

export type DeleteServicePlan = {
    deleteServicePlan: string
}

const client = generateClient();


export const fetchRecords = async (filter: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<ListServicePlans>>({
                                                                                  query: (listServicePlans),
                                                                                  variables: {filter: filter},
                                                                              });
        return response.data?.listServicePlans.items;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetServicePlan>>({
                                                                                query: (getServicePlan),
                                                                                variables: {id: id},
                                                                            });
        return response.data?.getServicePlan;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const add = async (payload: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<CreateServicePlan>>({
                                                                                   query: (createServicePlan),
                                                                                   variables: {input: payload},
                                                                               });

        showSuccessNotification();
        return response.data?.createServicePlan;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: ServicePlan) => {

    console.debug('IN UPDATE REGISTRAR', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateServicePlan>>({
                                                                                   query: (updateServicePlan),
                                                                                   variables: {input: record},
                                                                               });

        showSuccessNotification();
        return response.data?.updateServicePlan;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteServicePlan>>({
                                                                                   query: (deleteServicePlan),
                                                                                   variables: {input: {id: id}},
                                                                               });
        showSuccessNotification();
        return response.data?.deleteServicePlan;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};
