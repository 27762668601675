import {I18n} from '@aws-amplify/core';

import {formatTimestamp} from '../../../utils/TableTimestamps';
import {NavLink} from 'react-router-dom';
import {Tag} from '../types';

export const tagsInitialColumnSet = [
    {
        title: I18n.get('Name'),
        width: '1fr',
        render: (item: Tag) => <NavLink to={`/tags/${item.id}`}>{item.name}</NavLink>,
    },
    {
        title: I18n.get('Description'),
        width: '1fr',
        render: (item: Tag) => item.description,
    },
    {
        title: I18n.get('Created'),
        width: '1fr',
        render: (item: Tag) => formatTimestamp(item.created as string),
    },
];
