import {
    Box,
    Grid,
} from '@mantine/core';

import {ProfileWidget} from './dashboard/ProfileWidget.tsx';
import {HealthWidget} from './dashboard/HealthWidget.tsx';
import {SupportWidget} from './dashboard/SupportWidget.tsx';
import {ReactNode} from "react";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const PortalDashboard = () => {

    const {allowedActions} = useVerifiedPermissions()

    const columns = {
        leftColumn: [],
        rightColumn: []
    } as { leftColumn: ReactNode[], rightColumn: ReactNode[] }

    if (allowedActions.includes('GetDashboard')) {
        columns.leftColumn.push(<ProfileWidget/>)
    }


    if (allowedActions.includes('ListMessages')) {
        columns.rightColumn.push(<HealthWidget/>)
    }

    if (allowedActions.includes('ListMessages')) {
        columns.rightColumn.push(<SupportWidget/>)
    }


    return (
        <Grid grow>
            <Grid.Col span={1} visibleFrom={'sm'}/>
            <Grid.Col span={{
                base: 4,
                sm: 12
            }}>
                {columns.leftColumn.map(item => <>{item}</>)}
            </Grid.Col>

            <Grid.Col span={{
                base: 6,
                sm: 12
            }}>
                {columns.rightColumn.map((item, index) => <Box key={index}>{item}</Box>)}
            </Grid.Col>
            <Grid.Col span={1} visibleFrom={'sm'}/>
        </Grid>
    );
};
