import {
    getAlias,
    listAliases
} from './queries';
import {
    createAlias,
    deleteAlias,
    updateAlias,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    Alias,
    CreateAlias,
    DeleteAlias,
    GetAlias,
    ListAliases,
    UpdateAlias
} from "../types.ts";
import {
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";
import {generateClient} from "aws-amplify/api";
import {getIdentityId} from "../../../services/Cognito/Identities.ts";
import {fetchAuthSession} from "aws-amplify/auth";

const client = generateClient();

export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListAliases>>({
                                                                             query: listAliases,
                                                                             variables: {filter: filter},
                                                                         });
        return response.data?.listAliases.items;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetAlias>>({
                                                                          query: getAlias,
                                                                          variables: {id: id},
                                                                      });
        return response.data?.getAlias;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const add = async (payload: object) => {

    const session = await fetchAuthSession();
    console.debug('CREDENTIALS', session.credentials);

    const getIdentityIdOutput = await getIdentityId(session.tokens?.idToken?.toString() as string)

    console.debug('GET COGNITO IDENTITY ID RESPONSE', getIdentityIdOutput);
    const identityId = getIdentityIdOutput?.IdentityId
    console.debug('COGNITO IDENTITY ID', identityId);

    try {
        const response = await client.graphql<GraphQLQuery<CreateAlias>>({
                                                                             query: createAlias,
                                                                             variables: {input: {...payload, ...{sub: identityId}}},
                                                                         });
        console.debug('in the service response', response);

        showSuccessNotification('Alias created');
        return response.data?.createAlias;
    } catch (e: any) {

        if (e.errors) {
            // filter out all other messages but the transaction cancelled exception
            e.errors = e.errors.filter((error: {
                errorType: string
            }) => error.errorType === 'DynamoDB:TransactionCanceledException')

            // iterate over the errors and replace the message if it is a duplicate
            e.errors.forEach((error: { errorType: string, message: string }) => {
                if (error.errorType === 'DynamoDB:TransactionCanceledException') {
                    error.message = 'Duplicate alias. please select another'
                }
            });
        }

        console.trace(e);
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: Alias) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateAlias>>({
                                                                             query: (updateAlias),
                                                                             variables: {input: record},
                                                                         });

        showSuccessNotification('Alias updated');
        return response.data?.updateAlias;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (id: string, name: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteAlias>>({
                                                                             query: (deleteAlias),
                                                                             variables: {
                                                                                 input: {
                                                                                     id: id,
                                                                                     name: name,
                                                                                 }
                                                                             },

                                                                         });
        showSuccessNotification('Alias deleted');
        return response.data?.deleteAlias;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};
