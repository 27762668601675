export const createTag = `
    mutation createTag($input: CreateTagInput!) {
        createTag(input:$input) {
            id
            name
            description
            createdByUsername
            created
            updated       
        }
}`;

export const updateTag = `
    mutation updateTag($input: UpdateTagInput!) {
        updateTag(input:$input){
            id
            name
            description
            createdByUsername
            created
            updated       
        }
}`;

export const deleteTag = `
    mutation deleteTag($input: DeleteTagInput!) {
        deleteTag(input:$input) 
}`;
