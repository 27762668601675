import {stage} from './stage';

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        portal: string;
        console: string;
        domains: string;
        email: string;
        books: string;
        bot: string;
    };
};

const platform: ConfigShape = {
    prod: {
        portal: 'https://portal.flexi.network',
        console: 'https://console.flexi.network',
        domains: 'https://www.flexi.domains',
        email: 'https://www.flexi.email',
        books: 'https://www.flexiebooks.com',
        bot: 'https://www.flexi.bot',
    },
    test: {
        portal: 'https://portal.test.flexi.network',
        console: 'https://console.test.flexi.network',
        domains: 'https://test.flexi.domains',
        email: 'https://www.flexi.email',
        books: 'https://www.flexiebooks.com',
        bot: 'https://www.flexi.bot',
    },
    dev: {
        portal: 'https://portal.dev.flexi.network',
        console: 'https://console.dev.flexi.network',
        domains: 'https://dev.flexi.domains',
        email: 'https://www.flexi.email',
        books: 'https://www.flexiebooks.com',
        bot: 'https://www.flexi.bot',
    },
    ide: {
        portal: 'https://localhost:8011',
        console: 'https://localhost:8012',
        domains: 'https://localhost:8021',
        email: 'https://localhost:8023',
        books: 'https://www.flexiebooks.com',
        bot: 'https://localhost:8022',
    },
};

export default platform[stage];
