import {
    decrementPage,
    incrementPage,
    resetPageSize,
    setPage,
    setPageSize,
} from '../reducers';

import {visibilitySelector,} from '../selectors';
import {
    useAppDispatch,
    useAppSelector
} from '../../../hooks/useTypedReactRedux';

export const useTagsPaging = () => {

    const dispatch = useAppDispatch();

    const {
        limit,
        page,
        totalPages,
    } =
        useAppSelector((state) => visibilitySelector(state));


    // PAGING
    const handlePageChange = (page: number) => {
        dispatch(setPage(page));
    };

    const handleIncrementPage = () => {
        dispatch(incrementPage());
    };

    const handleDecrementPage = () => {
        dispatch(decrementPage());
    };

    const handleItemsPerPageChange = (pageSize: string | null) => {
        dispatch(setPageSize(pageSize));
    };

    const handleResetPageSize = () => {
        dispatch(resetPageSize());
    };

    return {
        limit,
        page,
        totalPages,
        handlePageChange,
        handleIncrementPage,
        handleDecrementPage,
        handleItemsPerPageChange,
        handleResetPageSize,
    };
};
