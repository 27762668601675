import {Message,} from '../types';
import {formatTimestamp} from "../../../utils/TableTimestamps.tsx";
import {
    DeleteRecordsConfirmationButton,
    FlexiButton,
    PlatformPropertyCard,
    PlatformPropertyCardCopyButtonDecorator
} from "@flexinet/ui-components";
import {
    Accordion,
    Badge,
    Group,
    Paper,
    Text
} from "@mantine/core";
import {
    AiOutlineBook,
    AiOutlinePaperClip,
    AiOutlineRead,
    AiOutlineSetting
} from "react-icons/ai";
import {TagRecordButton} from "../../tags/buttons/TagRecordButton.tsx";
import {showSuccessNotification} from "../../../components/ServiceNotifications.tsx";
import {getUrl} from "aws-amplify/storage";
import {Tag} from "../../tags/types.ts";
import {useNavigate} from "react-router-dom";

const TagsTable = ({value}: { value: Tag[] | null | undefined }) => {
    return (
        <>
            {value && value.map((tag, index) => (
                                    <Badge key={index}><Text>{tag.name}</Text></Badge>
                                )
            )}
        </>
    );
};


export const Profile = ({
                            record,
                            handleUpdate,
                            handleDelete
                        }: {
    record: Message,
    handleUpdate: (record: Message) => void,
    handleDelete: () => void
}) => {


    const navigate = useNavigate()

    const properties = [
        {
            label: 'From',
            value: record.fromText,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 6,
        }, {
            label: 'To',
            value: record.toText,
            decorator: (value: string) => <PlatformPropertyCardCopyButtonDecorator value={value}/>,
            span: 6,
        }, {
            label: 'Subject',
            value: record.subject,
            span: 6,
        }, {
            label: 'Received',
            value: formatTimestamp(record.date as string),
            span: 6,
        }];

    const metadata = [
        {
            label: 'Message ID',
            value: record.messageId as string,
            span: 6,
        }, {
            label: 'Status',
            value: record.status,
            span: 6,
        }, {
            label: 'Html Path',
            value: record.htmlPath,
            span: 12,
        }, {
            label: 'Created By',
            value: typeof record.createdBy !== 'undefined' && record.createdBy !== null ? [record.createdBy.givenName, record.createdBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateMessage',
            span: 3,
        },
        {
            label: 'Created',
            value: record.created as string,
            formatAs: 'Timestamp',
            span: 3,
        }, {
            label: 'Managed By',
            value: typeof record.managedBy !== 'undefined' && record.managedBy !== null ? [record.managedBy.givenName, record.managedBy.familyName].join(' ') : 'n/a',
            rbac: 'UpdateMessage',
            span: 3,
        },
        {
            label: 'Last Updated',
            value: record.updated as string,
            formatAs: 'Timestamp',
            span: 3,
        }];

    const {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        selected,
        ...rest
    } = record as Message & { selected: boolean };

    const showAttachment = async (attachment: { filename: string, filePath: string, objectKey: string }) => {

        const getUrlResult = await getUrl({
                                              path: attachment.filePath
                                          });

        console.debug(getUrlResult.url)
        window.open(getUrlResult.url, '_blank')

    };

    return (
        <>
            <Group p={'sm'}>
                <FlexiButton leftSection={<AiOutlineRead/>} variant="subtle" onClick={() => {

                    const newStatus = record.status === 'READ' ? 'UNREAD' : 'READ';
                    handleUpdate({...rest, ...{status: newStatus}});

                }}>Read / Unread </FlexiButton>


                <TagRecordButton
                    disabled={false}
                    record={record}
                    handleConfirm={() => {
                        showSuccessNotification();
                        navigate(`/messages/${record.id}`);
                    }}/>

                <DeleteRecordsConfirmationButton
                    disabled={false}
                    variant={'subtle'}
                    handleConfirm={() => {
                        handleDelete()
                        navigate(-1);
                    }}/>

            </Group>

            <Group p={'sm'}>
                <TagsTable value={record.tags?.items}/>
            </Group>

            <Accordion defaultValue={'details'}>
                <Accordion.Item key={'details'} value="details">
                    <Accordion.Control icon={<AiOutlineBook/>}>Message Details</Accordion.Control>
                    <Accordion.Panel>
                        <PlatformPropertyCard properties={properties}/>
                    </Accordion.Panel>
                </Accordion.Item>
                <Accordion.Item key={'metadata'} value="metadata">
                    <Accordion.Control icon={<AiOutlineSetting/>}>Message Metadata</Accordion.Control>
                    <Accordion.Panel>
                        <PlatformPropertyCard properties={metadata}/>
                    </Accordion.Panel>
                </Accordion.Item>

            </Accordion>

            {record.attachments && record.attachments.length > 0 &&
                <Group p={'sm'}>
                    {record.attachments.map(attachment => <Paper withBorder
                                                                 p={'md'}
                                                                 shadow={'sm'}
                                                                 onClick={() => showAttachment(attachment)}
                                                                 style={{cursor: 'pointer'}}
                    ><Group><AiOutlinePaperClip
                        size={'20'}/><Text>{attachment.filename}</Text></Group></Paper>)}
                </Group>
            }

        </>
    );

};
