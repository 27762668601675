import {useNavigate} from 'react-router-dom';

import {Button} from '@mantine/core';
import {useToggle} from '@mantine/hooks';


import {AiOutlineProfile} from 'react-icons/ai';
import {IconContext} from 'react-icons';
import {
    FlexiCloseButton,
    FlexiEditButton
} from "@flexinet/ui-components";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";

export const useEditPageTools = () => {

    const navigate = useNavigate();

    const {allowedActions} = useVerifiedPermissions()

    const [mode, toggle] = useToggle(['view', 'edit']);

    const tools = [];


    if (allowedActions.includes('UpdateTag')) {

        if (mode === 'edit') {
            tools.push(<Button radius='xs' size='md' variant={'filled'} key={'profile'}
                               leftSection={<IconContext.Provider value={{size: '24px'}}>
                                   <AiOutlineProfile/></IconContext.Provider>}
                               onClick={() => toggle()}>Profile</Button>);
        } else {
            tools.push(<FlexiEditButton key={'edit'} onClick={() => toggle()}/>);
        }

    }

    tools.push(<FlexiCloseButton key={'close'} onClick={() => navigate(`/tags`)}/>);

    return {
        mode,
        tools,
    };

};
