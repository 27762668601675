import {PlatformNavbarMenuItem} from "@flexinet/ui-components";
import {v4 as uuidv4} from 'uuid';


import {
    AiOutlineAim,
    AiOutlineHome,
    AiOutlineInbox,
    AiOutlineMail,
    AiOutlineRead,
    AiOutlineTag,
    AiOutlineTransaction,
    AiOutlineUser
} from 'react-icons/ai'

import {IconContext} from "react-icons";

export const getMenuItems = () => {

    return [
        {
            id: uuidv4(),
            link: '/dashboard',
            label: 'Dashboard',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineHome/></IconContext.Provider>,
            rbac: 'GetDashboard',
        }, {
            id: uuidv4(),
            link: '/messages',
            label: 'Email Messages',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineMail/></IconContext.Provider>,
            rbac: 'ListMessages',
            defaultOpened: true,
            links: [{
                id: 'all',
                link: '/messages/status/inbox',
                label: 'Inbox',
                icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineInbox/></IconContext.Provider>,
                rbac: 'ListMessages',
            }, {
                id: 'read',
                link: '/messages/status/read',
                label: 'Read',
                icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineRead/></IconContext.Provider>,
                rbac: 'ListMessages',
            }],
        }, {
            id: uuidv4(),
            link: '/aliases',
            label: 'Aliases',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineAim/></IconContext.Provider>,
            rbac: 'ListAliases',
        }, {
            id: uuidv4(),
            link: '/tags',
            label: 'Tags',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineTag/></IconContext.Provider>,
            rbac: 'ListTags',
        }, {
            id: uuidv4(),
            link: '/contacts',
            label: 'Contacts',
            icon: <IconContext.Provider value={{size: '2em'}}><AiOutlineUser/></IconContext.Provider>,
            rbac: 'ListContacts',
        }, {
            id: uuidv4(),
            link: '/service-plans',
            label: 'Service Plans',
            icon: <AiOutlineTransaction size={'2em'}/>,
            rbac: 'ListServicePlans',
        }] as PlatformNavbarMenuItem[];

};

