import {stage} from '../stage';
import {ResourcesConfig} from "aws-amplify";

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        domain: string;
        redirectSignIn: string[];
        redirectSignOut: string[];
        region: string;
        userPoolId: string;
        userPoolClientId: string;
        identityPoolId: string;
    };
};

const cognito: ConfigShape = {
    prod: {
        domain: 'portal.flexi.network',
        redirectSignIn: ['https://www.flexi.email'],
        redirectSignOut: ['https://portal.flexi.network'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_bxdNeqDGn',
        userPoolClientId: '467rlh361q573kme7313svd3sb',
        identityPoolId: 'us-west-2:a566963b-048f-4ffa-9df6-89814738257a',
    },
    test: {
        domain: 'portal.test.flexi.network',
        redirectSignIn: ['https://test.flexi.email'],
        redirectSignOut: ['https://portal.test.flexi.network'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_UuiFFepVO',
        userPoolClientId: '4l6lu4764mgkk0s6jfq5ebq2c8',
        identityPoolId: 'us-west-2:d7a2833c-863d-46e4-85a3-16b116ef25fc',
    },
    dev: {
        domain: 'portal.dev.flexi.network',
        redirectSignIn: ['https://dev.flexi.email'],
        redirectSignOut: ['https://portal.dev.flexi.network'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_INBgfMat1',
        userPoolClientId: '52m9svts4nvks7qi0ft8lt0oct',
        identityPoolId: 'us-west-2:d806f8ff-118f-4444-917f-e85833880089',
    },
    ide: {
        // domain: 'flexinet-main-user-pool-dev.auth.us-west-2.amazoncognito.com',
        domain: 'localhost:8011',
        redirectSignIn: ['https://localhost:8023'],
        redirectSignOut: ['https://localhost:8011'],
        region: 'us-west-2',
        userPoolId: 'us-west-2_INBgfMat1',
        userPoolClientId: '52m9svts4nvks7qi0ft8lt0oct',
        identityPoolId: 'us-west-2:d806f8ff-118f-4444-917f-e85833880089',
    },
};


export const config = {
    Cognito: {
        // Amazon Cognito User Pool ID
        userPoolId: cognito[stage].userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolClientId: cognito[stage].userPoolClientId,

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: cognito[stage].identityPoolId,

        // OPTIONAL - Set to true to use your identity pool's unauthenticated role when user is not logged in
        allowGuestAccess: false,

        loginWith: {
            oauth: {
                // Domain name
                domain: cognito[stage].domain,

                // Authorized scopes
                scopes: [
                    'phone',
                    'email',
                    'profile',
                    'openid',
                    'aws.cognito.signin.user.admin',
                ],

                // Callback URL
                redirectSignIn: cognito[stage].redirectSignIn,

                // Sign out URL
                redirectSignOut: cognito[stage].redirectSignOut,

                // 'code' for Authorization code grant,
                // 'token' for Implicit grant
                responseType: 'token',
            },
        }


    }
} as ResourcesConfig['Auth'];