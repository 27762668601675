import {stage} from '../stage';

type ConfigShape = {
    [key in 'prod' | 'test' | 'dev' | 'ide']: {
        endpoint: string;
        region: string;
    };
};

const appsync: ConfigShape = {
    prod: {
        endpoint: 'https://wn24qdlht5fvflqg6f7jkhxqri.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    test: {
        endpoint: 'https://xwccyhsa4rewbjsdsrljef7w4i.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    dev: {
        endpoint: 'https://hfl35zb5anf2pdy5vnxhrzt3ou.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
    ide: {
        endpoint: 'https://hfl35zb5anf2pdy5vnxhrzt3ou.appsync-api.us-west-2.amazonaws.com/graphql',
        region: 'us-west-2',
    },
};

export const appSyncConfig = appsync[stage];
