import {
    ReactElement,
    useEffect,
} from 'react';

import {
    Center,
    Checkbox,
    SimpleGrid,
    Table,
} from '@mantine/core';

import {
    RowSelectionDataCell,
    SimpleTableBatchAction,
    SimpleTablePagination,
    TableBody,
    TableBodyColumn,
    TableHeaderColumn,
    TableHeaderRow,
} from '@flexinet/ui-components';

import PropTypes from 'prop-types';
import {useTagsSelection} from '../hooks/useTagsSelection.ts';
import {ITagTableColumn,} from '../types';
import {useTags} from "../hooks/useTags.ts";
import {useTagsTableColumns} from "../hooks/useTagsTableColumns.ts";
import {useTagsSearch} from "../hooks/useTagsSearch.ts";
import {useTagsPaging} from "../hooks/useTagsPaging.ts";
import {useAuth} from "../../../hooks/useAuth.ts";
import {useVerifiedPermissions} from "../../../hooks/useVerifiedPermissions.ts";


export const RecordsTable = ({
                                 extraTools,
                                 settings,
                             }: {
    extraTools?: ReactElement[],
    settings?: { label: string, onClick: () => void }[]
}) => {


    const {
        user
    } = useAuth();


    if (!user) {
        throw new Error('user is required parameter')
    }

    const cognitoUsername = user.username;

    const {allowedActions} = useVerifiedPermissions()

    const {
        visibleItems,
        loading,
        visibleCount,
        fetchRecords,
    } = useTags();

    const {
        columns,
    } = useTagsTableColumns();

    const {
        keyword,
        handleFilterByKeyword,
    } = useTagsSearch();

    const {
        limit,
        totalPages,
        handleItemsPerPageChange,
        handlePageChange,
    } = useTagsPaging();


    const {
        selection,
        handleRowSelection,
        handleSelectAllRows,
    } = useTagsSelection();

    useEffect(() => {
        fetchRecords();
    }, []);


    const getColumns = () => {
        let cols: ITagTableColumn[] = [];
        if (allowedActions.includes('DeleteTag')) {

            cols.push({
                          id: 'select',
                          title: '',
                          render: item => {
                              if (item.createdByUsername === cognitoUsername || item.managedByUsername === cognitoUsername) {
                                  return <RowSelectionDataCell item={item} handleChange={handleRowSelection}/>;
                              } else {
                                  return (<Center><Checkbox disabled={true}/></Center>);
                              }

                          },


                      },
            );
        }

        cols = cols.concat(columns);

        return cols;


    };


    return (
        <SimpleGrid verticalSpacing='xl' m='sm'>
            <SimpleTableBatchAction
                limit={limit}
                handleItemsPerPageChange={handleItemsPerPageChange}
                settings={settings}
                keyword={keyword}
                handleFilterByKeyword={handleFilterByKeyword}
                extraTools={extraTools}
            />
            <Table verticalSpacing='sm' striped highlightOnHover>
                <TableHeaderRow
                    columns={getColumns() as TableHeaderColumn[]}
                    handleSelectAllRows={handleSelectAllRows}
                />
                <TableBody
                    items={visibleItems}
                    loading={loading}
                    selection={selection}
                    columns={getColumns() as TableBodyColumn[]}
                />
            </Table>

            <SimpleTablePagination
                totalPages={totalPages}
                visibleCount={visibleCount}
                handlePageChange={handlePageChange}
            />
        </SimpleGrid>
    );
};

RecordsTable.propTypes = {
    extraTools: PropTypes.array,
    settings: PropTypes.object,
};