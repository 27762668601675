import {
    getTag,
    listTags
} from './queries';
import {
    createTag,
    deleteTag,
    updateTag,
} from './mutations';

import {GraphQLQuery,} from '@aws-amplify/api';
import {
    CreateTag,
    DeleteTag,
    GetTag,
    ListTags,
    Tag,
    UpdateTag
} from "../types.ts";
import {
    showErrorNotifications,
    showSuccessNotification
} from "../../../components/ServiceNotifications.tsx";
import {generateClient} from "aws-amplify/api";


const client = generateClient();

export const fetchRecords = async (filter: object) => {
    try {
        const response = await client.graphql<GraphQLQuery<ListTags>>({
                                                                          query: listTags,
                                                                          variables: {filter: filter},
                                                                      });
        return response.data?.listTags.items;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const fetch = async (id: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<GetTag>>({
                                                                        query: getTag,
                                                                        variables: {id: id},
                                                                    });
        return response.data?.getTag;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const add = async (payload: object) => {

    try {
        const response = await client.graphql<GraphQLQuery<CreateTag>>({
                                                                           query: createTag,
                                                                           variables: {input: {...payload}},
                                                                       });
        console.debug('in the service response', response);

        showSuccessNotification('Tag created');
        return response.data?.createTag;
    } catch (e: any) {

        if (e.errors) {
            // filter out all other messages but the transaction cancelled exception
            e.errors = e.errors.filter((error: {
                errorType: string
            }) => error.errorType === 'DynamoDB:TransactionCanceledException')

            // iterate over the errors and replace the message if it is a duplicate
            e.errors.forEach((error: { errorType: string, message: string }) => {
                if (error.errorType === 'DynamoDB:TransactionCanceledException') {
                    error.message = 'Duplicate tag. please select another'
                }
            });
        }

        console.trace(e);
        showErrorNotifications(e)
    }
};

export const update = async (id: string, record: Tag) => {

    console.debug('IN UPDATE', id, record);

    delete record.created;
    delete record.updated;

    try {
        const response = await client.graphql<GraphQLQuery<UpdateTag>>({
                                                                           query: (updateTag),
                                                                           variables: {input: record},
                                                                       });

        showSuccessNotification('Tag updated');
        return response.data?.updateTag;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};

export const deleteRecord = async (id: string, name: string) => {
    try {
        const response = await client.graphql<GraphQLQuery<DeleteTag>>({
                                                                           query: (deleteTag),
                                                                           variables: {
                                                                               input: {
                                                                                   id: id,
                                                                                   name: name,
                                                                               }
                                                                           },

                                                                       });
        showSuccessNotification('Tag deleted');
        return response.data?.deleteTag;
    } catch (e) {
        console.trace(e);
        showErrorNotifications(e)
    }
};
