import {
    createAsyncThunk,
    createSlice,
} from '@reduxjs/toolkit';

import {
    add,
    deleteRecord,
    fetch,
    fetchRecords,
    update,
} from '../services/MessagesService.ts';


import {
    deleteOneCase,
    fetchManyCase,
    fetchOneCase,
    saveOneCase,
    updateOneCase,
} from '../../../store/RecordsSliceCaseHelpers';
import {Message} from '../types';

const entityKey = 'messages';

interface MessagesState {
    byId: { [key: string]: Message },
    ids: string[],
    totalCount: number,
    loading: boolean,
    error: string
}

const initialState = {
    byId: {},
    ids: [],
    totalCount: 0,
    loading: false,
    error: '',
} as MessagesState;


const normalizeRecord = (record: Message) => {
    return Object.assign({}, record);
};

// generates pending, fulfilled and rejected
export const fetchMessages = createAsyncThunk(
    `${entityKey}/fetchRecords`,
    (filter: { tagId?: string, status?: string }) => {

        return fetchRecords(filter)
            .then((response) => {
                if (response) {
                    return response.map((record: Message) => {
                        return normalizeRecord(record);
                    });
                }
            });
    },
);

export const fetchMessage = createAsyncThunk(
    `${entityKey}/fetch`,
    (id: string) => {
        return fetch(id)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const saveMessage = createAsyncThunk(
    `${entityKey}/add`,
    (payload: Message) => {
        return add(payload)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    });

export const updateMessage = createAsyncThunk(
    `${entityKey}/update`,
    ({
         id,
         record,
     }: { id: string, record: Message }) => {

        return update(id, record)
            .then((response) => {
                if (response) {
                    return normalizeRecord(response);
                }
            });
    },
);

export const deleteMessage = createAsyncThunk(
    `${entityKey}/deleteRecord`,
    (id: string) => {
        return deleteRecord(id)
            .then(() => id);
    },
);


export const storeMessage = createAsyncThunk(
    `${entityKey}/store`,
    (payload: Message) => {
        return normalizeRecord(payload);
    },
);


const componentsSlice = createSlice({
                                        name: entityKey,
                                        initialState,
                                        reducers: {
                                            clearState: () => initialState,
                                        },
                                        extraReducers: (builder) => {
                                            // FETCH MANY
                                            fetchManyCase(builder, fetchMessages, entityKey);

                                            // FETCH ONE
                                            fetchOneCase(builder, fetchMessage);

                                            // SAVE ONE
                                            saveOneCase(builder, saveMessage);

                                            // UPDATE ONE
                                            updateOneCase(builder, updateMessage);

                                            updateOneCase(builder, storeMessage);

                                            // DELETE ONE
                                            deleteOneCase(builder, deleteMessage);
                                        },
                                    });

export default componentsSlice.reducer;
