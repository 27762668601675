import {DeleteRecordsConfirmationButton,} from '@flexinet/ui-components';

import {useMessages} from '../hooks/useMessages';
import {useMessagesSelection} from "../hooks/useMessagesSelection.ts";

export const DeleteRecordButton = () => {

    const {deleteRecords} = useMessages();

    const {
        selection,
        handleClearSelection,
    } = useMessagesSelection();

    const recordIds = Object.keys(selection);
    let disabled = true;
    if (recordIds.length > 0) {
        disabled = false;
    }

    return (<DeleteRecordsConfirmationButton
        disabled={disabled}
        recordIdsLength={recordIds.length}
        handleConfirm={() => {
            deleteRecords()
            handleClearSelection()
        }}/>);
};

