export const listTags = `
   query listTags($filter: TagsFilterInput, $limit: Int, $nextToken: String) {
       listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
           items {
              id
              name
              description
              createdByUsername
              created
              updated
           }
       }
   }
`;

export const getTag = `
   query getTag($id:ID!) {
       getTag(id:$id) {
         id
         name
         description
         createdByUsername
         created
         updated
       } 
}`;
