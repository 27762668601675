import {I18n} from 'aws-amplify/utils';

import {
    Group,
    Modal,
    Stack,
    Text,
    Title
} from '@mantine/core';
import {AiOutlineTag} from 'react-icons/ai'
import {useDisclosure} from "@mantine/hooks";


import {
    FlexiButton,
    FlexiCancelButton,
    FlexiSaveButton,
    FormikMultiSelect
} from "@flexinet/ui-components";
import {
    Field,
    Form,
    Formik
} from "formik";
import {useMessage} from "../../messages/hooks/useMessage.ts";
import {useTags} from "../hooks/useTags.ts";
import {useEffect} from "react";
import {Tag} from "../types.ts";


export const TagRecordButton = ({
                                    disabled = false,
                                    record,
                                    handleConfirm,
                                }: {
    disabled?: boolean,
    record: { id: string, tagIds?: string[] },
    handleConfirm: () => void,
}) => {

    const {
        updateRecord,
    } = useMessage(record.id as string)

    const {
        items: tags,
        fetchRecords: fetchTags
    } = useTags();

    const [opened, {
        open,
        close
    }] = useDisclosure(false);

    useEffect(() => {
        fetchTags();
    }, []);


    const tagOptions = tags.map((tag: Tag) => ({
        value: tag.id,
        label: tag.name
    }));


    const initialValues = {
        tagIds: record.tagIds || []
    };


    return (
        <>
            <FlexiButton
                disabled={disabled}
                leftSection={<AiOutlineTag/>}
                variant="subtle"
                onClick={open}
            >
                {I18n.get('Tag')}
            </FlexiButton>

            <Modal
                title={<Title order={5} tt={"uppercase"}><Text>{I18n.get('Message Tags')}</Text></Title>}
                withCloseButton={false}
                onClose={close}
                opened={opened}
                size={'lg'}
                transitionProps={{
                    transition: 'fade',
                    duration: 200
                }}
            >

                <Formik initialValues={initialValues} onSubmit={(values: { tagIds: string[] }) => {

                    const {
                        selected,
                        ...rest
                    } = record as { id: string, tagIds?: string[] } & { selected: boolean };

                    console.debug("removing selected", selected)

                    const updated = {
                        ...rest,
                        tagIds: values.tagIds
                    }
                    updateRecord(updated)
                }}>
                    <Form>
                        <Stack>
                            <Field name={'tagIds'} label={I18n.get('Tags')} data={tagOptions}
                                   component={FormikMultiSelect}></Field>
                            <Group justify={"right"}>
                                <FlexiCancelButton onClick={close}/>
                                <FlexiSaveButton onClick={() => {
                                    //event.preventDefault()
                                    handleConfirm();
                                    close()
                                }}/>
                            </Group>
                        </Stack>
                    </Form>
                </Formik>
            </Modal>
        </>
    );
};



