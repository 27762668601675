export const createMessage = `
    mutation createMessage($input: CreateMessageInput!) {
        createMessage(input:$input) {
            id
            messageId
            status
            created
            updated  
    }
}`;

export const updateMessage = `
    mutation updateMessage($input: UpdateMessageInput!) {
        updateMessage(input:$input){
             id
             messageId
             fromText
             toText
             subject
             date,
             htmlPath
             textPath
             attachments {
                filename
                filePath
                objectKey
             }
             tagIds
             status
             created
             updated
        }
}`;

export const deleteMessage = `
    mutation deleteMessage($input: DeleteMessageInput!) {
        deleteMessage(input:$input) 
}`;
